import React from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
import { StaticImage } from 'gatsby-plugin-image'
import { Button } from 'components/styled/button'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  margin-bottom: 60px;
  margin-top: 150px;
  .title {
    margin: 0;
    font-size: 30px;
    grid-column: 1 / 2;
    grid-row: 1;
    max-width: 90%;
  }
  .img-container {
    position: relative;
    z-index: 1000;
    grid-column: 2 / 3;
    grid-row: 1;
    margin-top: -90px;
    margin-bottom: -9px;
    img {
        position: absolute;
        object-fit: cover;
        opacity: 1;
        width: 330px;
        height: 229px;
    }
    .gatsby-image-wrapper {
        overflow: visible;
    }
  }
  .button-container {
    grid-column: 3 / 4;
    grid-row: 1;
    button {
        font-size: 32px;
        padding: 16px;
        white-space: nowrap;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-top: 100px;
    padding: 16px;
    .img-container {
        margin-top: 0;
    }
    
    .title {
      font-size: 26px;
      max-width: unset;
      grid-column: 2 / 2;
      grid-row: 1 / 2;
      text-align: center;
      margin-top: 40px;
    }
    
      .img-container {
        grid-column: 2 / 2;
        grid-row: 3 / 4;
      }
    
      .button-container {
        grid-column: 2 / 2;
        grid-row: 2 / 3;
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
  }
`
const DescriptionContainer = styled.div`
 
  max-width: 1520px;
  margin-inline: auto;
  width: 100%;
  border: 3px solid #ecfb70;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
  padding-inline: 36px;
  align-items: center;
  grid-gap: 10px; /* Optional */
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    padding-inline: 20px;
    padding-top: 20px;

    .title {
        max-width: 100% !important;
    }

  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    grid-template-columns: 1fr; /* Single column for mobile */
    grid-template-rows: auto auto; /* Auto rows for mobile */
    justify-content: center;
  }
`

const CTA = ({ text }) => {
  return (
    <Container>
      <DescriptionContainer>
        <h2 class="title">{text}</h2>
        <div class="img-container">
          <StaticImage
            src="../../images/albert.png"
            alt="workspace"
            layout="constrained"
            
            placeholder="tracedSVG"
          />
        </div>
        <div class="button-container">
            <Button onClick={() => {
              window.open("https://calendly.com/buildup-ab/15min?month=2023-02", '_blank').focus();
                }}>Book a call</Button>
        </div>
      </DescriptionContainer>
    </Container>
  )
}

export default CTA
