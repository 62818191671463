import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

// COMPONENTS
import { H1 } from 'components/styled/typography'
import { fadeInText1, fadeInText2, fadeInText3, fadeInText4, fadeOut, load } from 'components/styled/animatedText'
import CloseIcon from '../../svgs/close-v2.svg'
import { Button, SecondaryButton } from 'components/styled/button'
import { FixedContainer } from '../styled/containers'

// ASSETS
import otterGIF from '../../gifs/hero-animation-v2.gif'
import PlayIcon from 'svgs/newDesigns/play-icon.svg'
import ArrowIcon from 'svgs/arrow_top_right.svg'
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 0 100px;

  .mt-60px {
    margin-top: 60px !important;
  }

  .playIcon {
    position: absolute;
    top: 50%;
    left: 47%;
    transform: scale(0.8) translate(-50%, -50%);
    z-index: 100;
    animation: pulse-black 2s infinite, apearance 1s forwards;
    cursor: pointer;
    opacity: 0;
    animation-delay: 5s, 4s;
    transition: all 0.2s;
    transform-origin: 0 0;

    &:hover {
      transform: scale(2) translate(-50%, -50%);
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      left: 50%;
    }
  }

  @keyframes apearance {
    0% {
      opacity 0;
    }


    100% {
      opacity: 1;
    }
  }

  @keyframes pulse-black {
    0% {
      transform: scale(0.7) translate(-50%, -50%);
      // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    50% {
      transform: scale(0.8) translate(-50%, -50%);
      // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.7) translate(-50%, -50%);
      // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  .hidden {
    transform: translateX(-100000px);
  }

  .apply-menu {
    z-index: 1;
  }

  .open-menu-on-hover {
    top: 0;
    margin: 0;
    height: auto;

    > button {
      margin-bottom: 0 !important;
    }
  }

  .pl-12px {
    padding-left: 12px;
  }

  .animation-holder {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 1rem;

    > * {
      position: relative;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      max-width: 100%;
    }
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .mt-60px {
      margin-top: 0 !important;
    }

    padding: 16px;
    .pl-12px {
      padding-left: 5px;
    }
  }
`

const HeadingsContainer = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 775px;
  min-height: 600px;
  padding-left: 24px;
  margin-right: 20px;
  animation: ${load} 3s ease both;
  width: 100%;
  background: white;

  > h1 {
    font-size: 6.5rem;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
  }

  &:before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 78%;
    content: '';
    background-color: var(--secondary);
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 8px;
    max-width: unset;
    min-height: 300px;
    &:before {
      width: 100%;
    }

    h1 {
      font-size: 60px;
    }

    img {
      max-width: 80% !important;
      width: 70% !important;
      // height: 90% !important;
      // transition: all 3s ease;
    }

    &:before {
      // width: 100% !important;
      // height: 90%;
    }
  }
`

const DescriptionContainer = styled.div`
  flex: 1 1 50%;
  max-width: 612px;
  margin-left: 20px;

  > h5 {
    font-size: 25px;
    font-weight: 400;
    line-height: 1.7;
  }

  div.d-flex button {
    white-space: nowrap;
    margin-bottom: 10px;
    margin-right: 12px;

    > svg {
      margin-left: 14px;
    }
  }

  small {
    max-width: 400px;
  }

  dialog {
    padding: 0;
    z-index: 1100;
    border-radius: var(--border-radius);
    background: transparent;
    border: 0;
    overflow: hidden;
    box-shadow: 0 0 0 100vmax rgb(0 0 0 / 0.65), 0 0 0.3rem rgb(0 0 0 / 0.44);
    position: fixed;
    inset: 0;
    margin: auto;
    width: min(90vw, 1050px);
    aspect-ratio: 16 / 9;

    iframe {
      vertical-align: middle;
      width: 100%;
      height: 100%;
    }
  }

  //TODO: Need TO BE REMOVED WITH APPLY DIALOG
  dialog.apply-dialog {
    background: #fff !important;
    padding: 0;
    z-index: 1100;
    border-radius: var(--border-radius);
    background: transparent;
    border: 0;
    overflow: hidden;
    box-shadow: 0 0 0 100vmax rgb(0 0 0 / 0.65), 0 0 0.3rem rgb(0 0 0 / 0.44);
    position: fixed;
    inset: 0;
    margin: auto;
    width: min(90vw, 800px);
    aspect-ratio: unset;

    .apply-dialog-header {
      background-color: var(--secondary);
      padding: 20px 30px;

      h3 {
        font-weight: normal !important;
        font-size: 34px;
      }
    }

    ul {
      list-style-type: none;
      padding-right: 30px;
      padding-left: 30px;

      li {
        border-top: 1px solid #000;
        padding: 20px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
          border-top: none;
        }

        &:hover {
          background-color: var(--secondary);
        }

        a {
          font-weight: 600;
          font-size: 18px;
          color: #000;
          text-decoration: none;
        }
      }
    }

    .close-icon {
      background: transparent;
      top: 10px;
      right: 10px;
      text-align: center;

      svg {
        width: 27px !important;
      }
    }
  }

  .close-icon {
    position: absolute;
    background: #fff;
    border-radius: 100%;
    padding: 0;
    width: 40px;
    height: 40px;
    right: 2px;
    top: 2px;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s ease-in-out opacity;

    &:hover {
      opacity: 0.75;
    }
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    dialog.apply-dialog {
      aspect-ratio: unset;

      .apply-dialog-header {
        padding: 20px;
      }

      h3 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 24px;
      }

      ul li {
        padding: 10px 10px;
      }
    }

    > h5 {
      margin-top: 0;
      font-size: 20px;
    }

    > p:first-child {
      margin-top: 0;
    }

    small {
      max-width: 290px;
    }
  }
`
const FadeOutText = styled(H1)`
  animation: ${fadeOut} 3s ease both;
  position: absolute;
`

const FadeInText1 = styled(H1)`
  letter-spacing: 0;
  animation: ${fadeInText1} 1s ease both;
  animation-delay: 2.5s;
`

const FadeInText2 = styled(H1)`
  letter-spacing: 0;
  animation: ${fadeInText2} 1.5s ease both;
  animation-delay: 2.5s;
`

const FadeInText3 = styled(H1)`
  letter-spacing: 0;
  animation: ${fadeInText3} 1.5s ease both;
  animation-delay: 2.5s;

  &:hover {
    color: var(--secondary);
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > span {
      letter-spacing: -3px;
    }
  }
`

const FadeInText4 = styled.div`
  display: flex;
  padding: 8px;
  animation: ${fadeInText4} 1s ease both;
  animation-delay: 2.5s;
  transition: 0.3s;

  > span {
    margin: 0;
    font-size: 33px;
    font-weight: 500;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 8px;
    height: 36px;
    margin-left: 0;
    > span {
      font-size: 20px;
      line-height: 28px;
    }
  }
`

const HeroSection = ({ setMobileCTAVisible, handleModal, programs, showModal = false }) => {
  const callback = useCallback(
    entries => {
      entries.forEach(({ isIntersecting }) => {
        setMobileCTAVisible(!isIntersecting)
      })
    },
    [setMobileCTAVisible],
  )

  useEffect(() => {
    let options = {}

    let observer = new IntersectionObserver(callback, options)
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
      setMobileCTAVisible(true)
    }
  }, [])

  //const [programs, setPrograms] = useState([])
  const [ah, setAh] = useState(true)

  /*useEffect(async () => {
    fetch(`https://backend.buildup.am/programs`)
      .then(response => response.json())
      .then(response => {
        setPrograms(response.filter(program => program.enabled))
      })
  }, [])*/

  let interval

  const updateTest = () => {
    interval = setInterval(() => {
      setAh(prev => !prev)
    }, 7000)
  }

  const closeModal = (name = "modal") => {
    if(name === 'programs-modal'){
      window.history.replaceState({},"Buildup",'/')
    }
    let modal = document.getElementById(name)
    modal.classList.add('d-none')
    const iframe = document.getElementById('youtube')
    const currentSource = iframe.src
    iframe.src = ''
    iframe.src = currentSource
  }

  const openModal = () => {
    let modal = document.getElementById('modal')
    modal.classList.remove('d-none')
  }

  useEffect(() => {
    updateTest()

    return () => clearInterval(interval)
  }, [])

  const ref = useRef()

  return (
    <Container ref={ref} className='mb-100px'>
      <FixedContainer>
        <div className='animation-holder'>
          <HeadingsContainer className={ah ? '' : 'hidden'}>
            <FadeOutText large>
              {getTranslation('buildup', 'BUILDUP')}
            </FadeOutText>
            <FadeInText1 inverted className='mt-60px'>
              <span className='pl-12px'>A</span>
            </FadeInText1>
            <FadeInText1 inverted>PROBLEM-</FadeInText1>
            <FadeInText2 inverted>
              {getTranslation('based', 'BASED')}
            </FadeInText2>
            <FadeInText3>{getTranslation('BvOOTCAMP', 'BOOTCAMP')}</FadeInText3>
            <FadeInText4>
              {getTranslation('for_the_top_5_percent', 'For the top 5%')}
            </FadeInText4>
          </HeadingsContainer>

          <img src={otterGIF} alt='' className={ah ? 'hidden' : ''} />

          <PlayIcon onClick={openModal} className='playIcon' />
        </div>

        <DescriptionContainer>
          {showModal &&
          <dialog open className='apply-dialog' id="programs-modal">
            <div className='apply-dialog-header'>
              <h3>
                Upcoming <strong>Programs</strong>
              </h3>
            </div>
            <div className='d-flex'>
              <div className='close-icon ms-auto'>
                <CloseIcon onClick={() => closeModal("programs-modal")} />
              </div>
            </div>
            <ul>
              {programs.map(program => {
                return (
                  <li key={program.key}>
                    <a href={`/program/${program.key}`}>{program.data.title}</a>
                    <ArrowIcon />
                  </li>
                )
              })}
            </ul>
          </dialog>
          }
          <dialog open className='d-none' id='modal'>
            <div className='d-flex'>
              <div className='close-icon ms-auto'>
                <CloseIcon onClick={() => closeModal()} />
              </div>
            </div>

            <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/slTndBsnR5Y'
              id='youtube'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </dialog>
          <h5>
            {getTranslation(
              'homepage_description_line_1',
              'BuildUp is a master-level, peer-to-peer, tuition-delayed product-based learning Bootcamp with a community funding model.',
              true
            )}
          </h5>

          {/* <h6>Join our community</h6> */}
          {/* <div className='d-flex'>
            <div className='position-relative open-menu-on-hover'>
              <Button
                onClick={() => {
                  window.location.href = '/program'
                }}
              >
                {getTranslation('Apply', 'Apply')} <ArrowIcon />
              </Button>
              <div
                className='position-absolute apply-menu left-0 d-none'
                id='apply-menu'
              >
                <ul>
                  {programs.map(program => {
                    return (
                      <li key={program.key}>
                        <a href={`/program/${program.key}`}>
                          {program.data.title}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <SecondaryButton
              onClick={() => (window.location.href = '/community')}
              secondry
            >
              Be a trustee <ArrowIcon />
            </SecondaryButton>
          </div> */}
        </DescriptionContainer>
      </FixedContainer>
    </Container>

  )
}

export default HeroSection
