import * as React from 'react'
import styled from 'styled-components'
import Line from 'svgs/line.svg'
import VerticalLine from 'svgs/vertical-line.svg'
import { StaticImage } from 'gatsby-plugin-image'
import { ShadowText } from 'components/styled/typography'
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  // min-height: 950px;
  img {
    max-width: 100%;
    height: auto; f
    max-height: 500px;
  }
  .z-index-0 {
    z-index: 0;
  }
  .mx-5 {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-none {
    display: none;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    align-items: start;
    .d-lg-block {
      display: none;
    }
    .d-lg-none {
      display: block;
    }
    img {
      max-width: 300px;
    }
  
  }
  
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    img {
      max-width: 160px;
    }
    &.mb-100px {
      margin-bottom: 170px !important;
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: wrap;
  > div {
    margin-bottom: 20px;
  }
  h6 {
    font-weight: 400;
    line-height: 1.8;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  > svg {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
   > svg {
     height: 100%;
     top: 0;
     transform: translateY(0);
   }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
   > svg {
     height: 100%;
     top: 66px;
   }

`

const ColThree = styled.div`
  flex: 0 0 auto;
  width: 25%;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 10;
  text-align: center;
  &:nth-child(even) {
    transform: translateY(calc(50% - 50px));
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    width: 50%;
    flex: 0 0 50%;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 0;
    &:nth-child(4) {
      transform: translateY(calc(-50% + 340px));
    }
    &:nth-child(5) {
      transform: translateY(calc(-50% + 240px));
    }

    &:nth-child(6) {
      transform: translateY(calc(-50% + 420px));
    }
  }
`

const HowDoesItWork = () => (
  <Container className="mb-100px">
    <FixedContainer>
      <ShadowText highlight>
        <h3>
          {getTranslation(
            'how_will_it_work_heading',
            'How does it work?',
            false,
          )}
        </h3>
      </ShadowText>
      <DescriptionContainer>
        <Row className="mt-100px">
          <Line className="z-index-0 d-none d-lg-block" />
          <VerticalLine className="z-index-0 d-lg-none" />
          <ColThree>
            <StaticImage
              src="../../images/step1.jpg"
              alt="workspace"
              layout="constrained"
              width={480}
              height={480}
              placeholder="tracedSVG"
            />
            <div className="mx-5">
              <p className="text-center title lh-1">
                <b>
                  {getTranslation(
                    'how_will_it_work_step_1_heading',
                    'Step 1',
                    false,
                  )}
                </b>
              </p>
              <p className="text-center">
                {getTranslation(
                  'how_will_it_work_step_1_description',
                  'Apply and pass selection process',
                  false,
                )}
              </p>
            </div>
          </ColThree>
          <ColThree>
            <StaticImage
              src="../../images/step2.jpg"
              alt="workspace"
              layout="constrained"
              width={480}
              height={480}
              placeholder="tracedSVG"
            />
            <div className="mx-5">
              <p className="text-center title lh-1">
                <b>
                  {getTranslation('how_will_it_work_step_2_heading', 'Step 2')}
                </b>
              </p>
              <p className="text-center">
                {getTranslation(
                  'how_will_it_work_step_2_description',
                  'Teamwork and online study',
                )}
              </p>
            </div>
          </ColThree>
          <ColThree>
            <StaticImage
              src="../../images/step3.jpg"
              alt="workspace"
              layout="constrained"
              width={480}
              height={480}
              placeholder="tracedSVG"
            />
            <div className="mx-5">
              <p className="text-center title lh-1">
                <b>
                  {getTranslation('how_will_it_work_step_3_heading', 'Step 3')}
                </b>
              </p>
              <p className="text-center">
                {getTranslation(
                  'how_will_it_work_step_3_description',
                  'Create cool product and publish on ProductHunt',
                )}
              </p>
            </div>
          </ColThree>
          <ColThree>
            <StaticImage
              src="../../images/step4.jpg"
              alt="workspace"
              layout="constrained"
              width={480}
              height={480}
              placeholder="tracedSVG"
            />
            <div className="mx-5">
              <p className="text-center title lh-1">
                <b>
                  {getTranslation('how_will_it_work_step_4_heading', 'Step 4')}
                </b>
              </p>
              <p className="text-center">
                {getTranslation(
                  'how_will_it_work_step_4_description',
                  'Get product job offer & increased salary',
                )}
              </p>
            </div>
          </ColThree>
        </Row>
      </DescriptionContainer>
    </FixedContainer>
  </Container>
)

export default HowDoesItWork
